
/* Small screens (phones) */
@media screen and (min-width: 576px) {
  .faq-items.expand {
    height: 300px;
  }

  .footer-bg-color {
    background: linear-gradient(101deg, #603998 0%, #18A1B9 100%);
  }
}

/* Medium screens (tablets) */
@media screen and (min-width: 768px) {
  .faq-items.expand {
    height: 300px;
  }

  .footer-bg-color {
    background: linear-gradient(101deg, #603998 0%, #18A1B9 100%);
  }
}

/* Large screens (laptops/desktops) */
@media screen and (min-width: 992px) {}

/* Extra-large screens (large laptops and desktops) */
@media screen and (min-width: 1200px) {}

@media screen and (min-width: 300px) and (max-height: 700px) {
  .roots-mobile-cp {
    margin-top: 100px;
    position: relative !important;
    width: 100% !important;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .footer-bg-color {
    background: linear-gradient(101deg, #603998 0%, #18A1B9 100%) !important;
  }
  .footer-bg-wrapper {
    height: auto;
    top: 0;
  }
}

@font-face {
  font-family: 'font-exo';
  src: url('./assets/fonts/Exo/static/Exo-Regular.ttf');
}
@font-face {
  font-family: 'font-exo-bold';
  src: url('./assets/fonts/Exo/static/Exo-Bold.ttf');
}
/* @import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css"; */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.section-product::-webkit-scrollbar {
  display: none;
}
.section-feature::-webkit-scrollbar {
  display: none;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::selection {
  background-color: #603998;
  color: white;
  /* Optional: Change the text color when highlighted */
}


.icon-arrow {
}

.exo-font {
  font-family: font-exo;
}
.exo-font-bold {
  font-family: font-exo-bold;
}
.faq-items {
  border-bottom: solid 2px #603998;
  height: 60px;
  margin: 10px 0px;
  overflow: hidden;
  transition: all 300ms ease-in;
}

.faq-items.expand {
  height: max-content;
}

.faq-items .content {
  color: #603998;
  padding-bottom: 2.5rem;
}

.faq-items:nth-child(5){
  border-bottom: none;
}

.footer-bg-color {
  background: rgb(91, 59, 147);
  background: linear-gradient(0deg, rgba(91, 59, 147, 1) 0%, rgba(121, 99, 153, 1) 60%, rgba(0, 212, 255, 0) 100%);
}
.footer-bg-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  height: 40rem;
  bottom: 0;
  z-index: -1;
}

.carousel-client {

}

.keysolution-mobile-slider .slick-slide {
  height: auto;
}
.keysolution-mobile-slider .slick-dots li {
  margin: 0px 15px;
}
.keysolution-mobile-slider .slick-dots li button::before{
  font-size: 24px;
  color: #603998;
  opacity: 0.6;
}
.keysolution-mobile-slider .slick-dots li.slick-active button:before {
  color: #603998;
  opacity: 1;
}

.menu-mobile-item-logo {
  padding-bottom: 1.6rem;
  border-bottom: 0.06rem #603998 solid;
}

.menu-mobile-item {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  border-bottom: 0.06rem #603998 solid;
}

@keyframes fadeIn {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0%);
  }
}

.side-bar {
  animation: fadeIn 300ms ease-in-out;
}